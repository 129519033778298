import { Routes, Route } from "react-router-dom";

import Home from "../screens/Comman/Home/Home";
import Profile from "../screens/Comman/selectProfile/SelectProfile";
import Otp from "../screens/Comman/OptVerification/Otp";
import CMS from "../screens/Comman/Cms/Cms";
import VideoList from "../screens/Comman/VideoList/VideoList";
import PlayVideo from "../screens/Comman/PlayVideo/PlayVideo";
import Faq from "../screens/Admin/FAQ/Faq";
import TermsAndConditions from "../screens/TermsAndConditions/TermsAndConditions";
import AboutUs from "../screens/AboutUs/AboutUs";
import ChangePwd from "../screens/Comman/ChangePwd/ChangePwd";
import Feedbacks from "../screens/Admin/Feedback/Feedbacks";

function UserRoutes() {
  return (
    <Routes>
      <Route path="/otp" element={<Otp />} />
      <Route path="/home" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/cms" element={<CMS />} />
      <Route path="/video-list" element={<VideoList />} />
      <Route path="/videos" element={<PlayVideo />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/change-password" element={<ChangePwd />} />
      <Route path="/feedbacks" element={<Feedbacks />} />
    </Routes>
  );
}

export default UserRoutes;
