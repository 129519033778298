import React from 'react'
import Cards from '../../../components/Card/Cards'

const Dashboard = () => {
  return (
    <div style={{color: 'black'}}> 
      <Cards />     
    </div>
  )
}

export default Dashboard
