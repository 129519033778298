import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { back } from '../../assets';

const Buttons = ({path, name}) => {
    const navigate = useNavigate();
  return (
    <Button
    variant="outlined"
    sx={{ mb: 2, color: "#6B599C", border: '1px solid #6B599C', borderRadius: '12px' }}
    onClick={() => navigate(path)}
  >
    <AddOutlinedIcon sx={{color: "#6B599C",fontSize: '15px'}} /> Add {name}
  </Button>
  )
}

export default Buttons

export const BackButton = ({path}) => {
  const navigate = useNavigate();
  return(
    <Button variant='outlined' sx={{borderRadius: "12px",border: '1px solid #6B599C', py:1}} onClick={() => navigate(path)} >
      <img src={back} alt="" height={23} />
    </Button>
  );
}