import React from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AppContext } from "../../../context/context";
import { useNavigate } from "react-router-dom";
import { BackButton } from "../../../components/Buttons/Buttons";

const AddUser = () => {
  const navigate = useNavigate();
  const {state} = AppContext();
  const {constants} = state[0];
  const {admin_user } = constants;

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    navigate(admin_user)
    // console.log({ categoryName, description, status });
  };


  return (
    <Box>
      <BackButton path={admin_user} />
      <Typography variant="h6" mt={3}>Add User</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop: "2rem",
        }}
      >
        <Grid container spacing={3} mb={4} >
          <Grid item xs={12} md={4}>
            <TextField
              label="User Name"
              variant="outlined"
              fullWidth
              required
            />
          </Grid>

        <Grid item xs={12} md={4}>
        <TextField label="Email" variant="outlined" fullWidth required />
          </Grid>

        <Grid item xs={12} md={4}>
        <TextField label="Password" variant="outlined" fullWidth required />
          </Grid>

          
        </Grid>        

        <Box sx={{ display: "flex", justifyContent: "end", gap: 3 }}>
          <Button type="button" variant="outlined" onClick={() => navigate(admin_user)}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Create
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddUser;
