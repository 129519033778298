import React from "react";
import { Box, Button,Grid,Rating,TextField,Typography,} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/context";
import Header from "../../../components/Navbar/header";
import ProfileSidebar from "../../../components/Navbar/profileSidebar";

const Feedbacks = () => {
  const navigate  = useNavigate();
  const {state} = AppContext();
  const {constants} = state[0];
  const { admin_faq, home } = constants;
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    // console.log({ categoryName, description, status });
  };

  return (
    // <Box>
    //   <Typography variant="h5" pb={3}>
    //     Add Feedback
    //   </Typography>    

    //   <Box>
    //     <Box
    //       component="form"
    //       onSubmit={handleSubmit}
    //       sx={{
    //         display: "flex",
    //         flexDirection: "column",
    //         gap: 3,
    //       }}
    //     >
         
    //      <Grid container spacing={3}>
    //       <Grid item xs={12} md={6}>
    //       <TextField
    //         label="Name"
    //         name="name"
    //         variant="outlined"
    //         fullWidth
    //         required
    //       />
    //       </Grid>
    //   <Grid item xs={12} md={6}>
    //         <TextField
    //         label="Email"
    //         name="email"
    //         variant="outlined"
    //         type="email"
    //         fullWidth
    //         required
    //       />
    //       </Grid>
    //   </Grid>
        

    //       <TextField
    //         label="Comments"
    //         name="comments"
    //         variant="outlined"
    //         fullWidth
    //         multiline
    //         rows={4}
    //         //   value={feedback.comments}
    //         //   onChange={handleChange}
    //       />

    //       <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
    //         <Typography component="legend">Rate Us </Typography>
    //         <Rating
    //           name="rating"
    //           // value={feedback.rating}
    //           // onChange={handleRatingChange}
    //           precision={0.5}
    //           size="large"
    //         />
    //       </Box>

    //       <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
    //         <Button variant="outlined"  type="button">
    //           Reset
    //         </Button>
    //         <Button variant="contained" color="primary" type="submit">
    //           Submit
    //         </Button>
    //       </Box>
    //     </Box>
    //   </Box>
    // </Box>

    <section className="cms-section">
    <div className="container">
      <Header />
      <div className="d-flex">
        <ProfileSidebar />

        <div className="termscondition">
          <h2>Share Feedback</h2>
          
          <Box width="65vw" mt={3} component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3} >
         <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            name="name"
            variant="outlined"
            fullWidth
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '13px', // Adjust the radius as needed
              },
            }}
          />
          </Grid>
      <Grid item xs={12} md={6}>
            <TextField
            label="Email"
            name="email"
            variant="outlined"
            type="email"
            fullWidth
            required
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '13px', // Adjust the radius as needed
              },
            }}
          />
          </Grid>
      </Grid>
        

          <TextField
            label="Comments"
            name="comments"
            variant="outlined"
            fullWidth
            sx={{mt: 3, '& .MuiOutlinedInput-root': {
                borderRadius: '13px', // Adjust the radius as needed
              },}}
            multiline
            required
            rows={4}
            
            //   value={feedback.comments}
            //   onChange={handleChange}
          />

            <Box
              sx={{ display: "flex", justifyContent: "end", gap: 3, mt: 2 }}
            >
              <Button
                type="button"
                variant="outlined"
                color="primary"
                sx={{ color: "#6f42c1", border: "1px solid #6f42c1", borderRadius: "13px" }}
                onClick={() => navigate(home)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ background: "#6f42c1", borderRadius: "13px" }}
              >
                Share
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Feedbacks;
