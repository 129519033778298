const constants = {

    main_route: "/",

    //admin routes
    admin_dashboard: "/admin/dashboard",
    admin_category: "/admin/category",
    admin_category_add: "/admin/category/add",
    admin_sub_category: "/admin/sub-category",
    admin_sub_category_add: "/admin/sub-category/add",
    admin_user: "/admin/user",
    admin_user_add: "/admin/user/add",
    // admin_terms_and_conditions: "/admin/terms-and-conditions",
    // admin_about_us: "/admin/about-us",
    // admin_faq: "/admin/faq",
    admin_faq_add: "/admin/faq/add",
    // admin_feedbacks: "/admin/feedbacks",

    //user routes
    otp: "/otp",
    home: "/home",
    profile: "/profile",
    video_list: "/video-list",
    videos: "/videos",
    cms: "/cms",
    admin_faq: "/faq",
    admin_terms_and_conditions: "/terms-and-conditions",
    admin_about_us: "/about-us",
    change_password: "/change-password",
    feedbacks: "/feedbacks"
}

export default constants;