/* eslint-disable jsx-a11y/anchor-is-valid */

import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/Navbar/header';
import Sidebar from '../../../components/Navbar/sidebar';
import { arrowleft, playimg, videoImg2 } from '../../../assets';
import { AppContext } from '../../../context/context';


function VideoList() {
    const navigate = useNavigate();
    const {state} = AppContext();
    const {constants} = state[0];
    const {home} = constants;
  return (
    <>
      <Header />
      <section className="home-section">
        <div className="container">
          <div className="main-section d-flex">
            <Sidebar />
            <div className="main-content">
              <div className="videoGroup">
                <div className="title d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <a onClick={() => navigate(home)} className="arrowleft"><img src={arrowleft} alt="arrow" /></a>
                    <h2 className='mb-0'>MISSION SOURATE</h2>
                  </div>
                </div>
                <div className="video-list d-flex flex-wrap">
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                    <ProgressBar now={40} />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VideoList;
