/* eslint-disable jsx-a11y/anchor-is-valid */

import ProgressBar from 'react-bootstrap/ProgressBar';
import { arrow, homeprofile, logosmall, playimg, videoImg, videoImg2, videoImg3 } from '../../../assets';


// function Home() {
//   return (
//     <section className="home-section">
//       <div className="home-header d-flex justify-content-between">
//         <img src={logosmall} alt="logo" className="logo" />
//         <img src={homeprofile} alt="homeprofile" className="homeprofile" />
//       </div>
//       <div>
//         <div className="videoGroup">
//           <div className="d-flex justify-content-between">
//             <h2>DRÔLE DE RÉCITATEUR</h2>
//             <button className="arrow"><img src={arrow} alt="arrow" /></button>
//           </div>
//           <div className="d-flex">
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//               <ProgressBar now={60} />;
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//           </div>
//         </div>

//         <div className="videoGroup">
//           <div className="d-flex justify-content-between">
//             <h2>MISSION SOURATE</h2>
//             <button className="arrow"><img src={arrow} alt="arrow" /></button>
//           </div>
//           <div className="d-flex">
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//               <ProgressBar now={40} />;
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg2} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//           </div>
//         </div>

//         <div className="videoGroup">
//           <div className="d-flex justify-content-between">
//             <h2>TAFSIR MYSTERY</h2>
//             <button className="arrow"><img src={arrow} alt="arrow" /></button>
//           </div>
//           <div className="d-flex">
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//               <ProgressBar now={80} />;
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg3} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//           </div>
//         </div>

//         <div className="videoGroup">
//           <div className="d-flex justify-content-between">
//             <h2>MOUSHAF DÉCODÉ</h2>
//             <button className="arrow"><img src={arrow} alt="arrow" /></button>
//           </div>
//           <div className="d-flex">
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//               <ProgressBar now={20} />;
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//             <div className="Playvideo">
//               <img src={videoImg} alt="videoImg" className="videoImg" />
//               <img src={playimg} alt="playimg" className="playimg" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Home;


import Header from '../../../components/Navbar/header';
import Sidebar from '../../../components/Navbar/sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/context';


function Home() {
  const navigate = useNavigate();
  const {state} = AppContext();
  const {constants} = state[0];
  const {video_list} = constants;
  return (
    <>
      <Header />
      <section className="home-section">
        <div className="container">
          <div className="main-section d-flex">
            <Sidebar />
            <div className="main-content">
              <div className="videoGroup">
                <div className="title d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h2 className='mb-0'>DRÔLE DE RÉCITATEUR</h2>
                  </div>
                  <a onClick={() => navigate(video_list)} className="arrow" ><img src={arrow} alt="arrow" /></a>
                </div>
                <div className="video-list d-flex overflow-auto">
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                    <ProgressBar now={60} />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                </div>
              </div>

              {/* <div className="videoGroup">
                <div className="title d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h2 className='mb-0'>MISSION SOURATE</h2>
                  </div>
                  <a onClick={() => navigate(video_list)} className="arrow"><img src={arrow} alt="arrow" /></a>
                </div>
                <div className="video-list d-flex overflow-auto">
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                    <ProgressBar now={40} />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg2} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                </div>
              </div>

              <div className="videoGroup">
                <div className="title d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h2 className='mb-0'>TAFSIR MYSTERY</h2>
                  </div>
                  <a onClick={() => navigate(video_list)} className="arrow"><img src={arrow} alt="arrow" /></a>
                </div>
                <div className="video-list d-flex overflow-auto">
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                    <ProgressBar now={80} />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg3} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                </div>
              </div>

              <div className="videoGroup">
                <div className="title d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h2 className='mb-0'>MOUSHAF DÉCODÉ</h2>
                  </div>
                  <a onClick={() => navigate(video_list)} className="arrow"><img src={arrow} alt="arrow" /></a>
                </div>
                <div className="video-list d-flex overflow-auto">
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                    <ProgressBar now={20} />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                  <Link to="/videos" className="Playvideo">
                    <img src={videoImg} alt="videoImg" className="videoImg" />
                    <img src={playimg} alt="playimg" className="playimg" />
                  </Link>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
