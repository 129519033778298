
import { Link } from "react-router-dom"
import { link1, link2, link3, link4, link5 } from "../../assets";

function Sidebar() {
  return (
    <nav className="sidebar">
      <Link to="/video-list">
        <img src={link1} alt="link" className="d-block" />
        DROLE DE RÉCITATEUR
      </Link>
      <Link to="/video-list">
        <img src={link2} alt="link" className="d-block" />
        TAFSIR MYSTERY
      </Link>
      <Link to="/video-list">
        <img src={link3} alt="link" className="d-block" />
        BLABLA BOX
      </Link>
      <Link to="/video-list">
        <img src={link4} alt="link" className="d-block" />
        MISSION SOURATE
      </Link>
      <Link to="/video-list">
        <img src={link5} alt="link" className="d-block" />
        USHAF DECODE
      </Link>
    </nav>
  );
}

export default Sidebar;
