/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { AppContext } from '../../context/context';
import { useLocation, useNavigate } from 'react-router-dom';
import { aboutus, boy, lock, login, message, paper } from '../../assets';
const profile = JSON.parse(localStorage.getItem("profile"));





const ProfileSidebar = () => {
    const {state} = AppContext();
    const {constants} = state[0];
    const {main_route, cms, home, admin_faq, admin_terms_and_conditions, admin_about_us, change_password, feedbacks} = constants;
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location >>', location);

    const ProfileSidebarData = [
      {
        name: "Edit profile",
        description: "Manage your personal informations",
        src: profile?.profile["src"],
        alt: profile?.profile["alt"],
        navigateTo: cms
      },
      {
        name: "Change Password",
        description: "Choose strong password",
        src: lock,
        alt: "lock",
        navigateTo: change_password
      },
      {
        name: "Terms & Conditions",
        description: "Policies",
        src: paper,
        alt: "paper",
        navigateTo: admin_terms_and_conditions
      },
      {
        name: "FAQ",
        description: "Frequently asked questions",
        src: message,
        alt: "message",
        navigateTo: admin_faq
      },
      {
        name: "About Us",
        description: "Find out more who we are",
        src: aboutus,
        alt: "aboutus",
        navigateTo: admin_about_us
      },
      {
        name: "Share Feedback",
        description: "Share your valuable feedback with us",
        src: aboutus,  // Assuming this is the same image as "About Us", consider replacing if there's a different icon
        alt: "aboutus",
        navigateTo: feedbacks
      },
      {
        name: "Log Out",
        description: "Log out from the application",
        src: login,
        alt: "login",
        navigateTo: main_route
      },
    ];
    
    
  return (
    <div className="sidelinks position-relative">
            {/* <a onClick={() => navigate(cms)} className="detail-sidelink d-flex">
              {/* <img src={smallprofile} alt="smallprofile"/> */}
              {/* <img src={profile?.profile["src"]} alt={profile?.profile["alt"]} className="homeprofile" height={35} />

              <div>
                <h3>Edit profile</h3>
                <p>Manage your personal informations</p>
              </div> */}
            {/* </a>
            <a onClick={() => navigate(change_password)} className="detail-sidelink d-flex">
              <img src={lock} alt="lock" />
              <div>
                <h3>Change Password</h3>
                <p>Choose strong password</p>
              </div>
            </a>
            <a onClick={() => navigate(admin_terms_and_conditions)} className="detail-sidelink d-flex">
              <img src={paper} alt="paper" />
              <div>
                <h3>Terms & Conditions</h3>
                <p>Policies</p>
              </div>
            </a>
            <a onClick={() => navigate(admin_faq)} className="detail-sidelink d-flex">
              <img src={message} alt="message" />
              <div>
                <h3>FAQ</h3>
                <p>Frequently asked questions</p>
              </div>
            </a>
            <a onClick={() => navigate(admin_about_us)} className="detail-sidelink d-flex">
              <img src={aboutus} alt="aboutus"/>
              <div>
                <h3>About Us</h3>
                <p>Find out more who we are</p>
              </div>
            </a>
            <a onClick={() => navigate(feedbacks)} className="detail-sidelink d-flex">
              <img src={aboutus} alt="aboutus" />
              <div>
                <h3>Share Feedback</h3>
                <p>Share your valuable feedback with us</p>
              </div>
            </a>
            <a onClick={() => navigate(main_route)} className="detail-sidelink d-flex">
              <img src={login} alt="login" />
              <div>
                <h3>Log Out</h3>
                <p>Log out from the application</p>
              </div>
            </a> */} 

            {
              ProfileSidebarData.map((itm, idx) => (
                <a key={idx} onClick={() => navigate(itm.navigateTo)} className="detail-sidelink d-flex"
                  style={{background: location?.pathname == itm.navigateTo ? "#FFE5D1": ""}}
                >
                  <img src={itm.src} alt={itm.alt} height={35} />
                  <div>
                    <h3>{itm.name}</h3>
                    <p>{itm.description}</p>
                  </div>
                </a>
              ))
            }

            <img src={boy} alt="boy" className="boy" />
          </div>
  )
}

export default ProfileSidebar