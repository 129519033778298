import { createContext, useContext, useReducer } from "react";
import constants from "../routes/constants";


const context = createContext();
const initialState=[{
    constants
}];
const reducer = (state, action) => {
    
    switch (action.type) {
        // case " " :
            
        //     break;
    
        default: return state
            break;
    }
}


export const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer,initialState);
    console.log('con state >>', state);

    return (
        <context.Provider value={{state, dispatch}}>
            {children}
        </context.Provider>
    );
}

export const AppContext = () => useContext(context);