
import Dropdown from 'react-bootstrap/Dropdown';
// import logosmall from "./../assets/images/logosmall.png"
// import homeprofile from "./../assets/images/home-profile.svg"
import { Link, useNavigate } from 'react-router-dom';
import { homeprofile, logosmall } from '../../assets';
import { AppContext } from '../../context/context';

function Header() {
  const {state} = AppContext();
  const {constants} = state[0];
  const {main_route, cms, home} = constants;
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("profile"));
  
  return (
    <header className="home-header">
      <div className="container d-flex justify-content-between">
        <Link to={home} className="logo">
          <img src={logosmall} alt="logo" className="logo" />
        </Link>
        <Dropdown className="header-dropdown">
          <Dropdown.Toggle id="dropdown-basic" variant='link' className='p-0'>
            {/* <img src={homeprofile} alt="homeprofile" className="homeprofile" /> */}
            <img src={profile?.profile["src"]} alt={profile?.profile["alt"]} className="homeprofile" height={51} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => navigate(cms)}>Profile</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate(main_route)}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}

export default Header;
