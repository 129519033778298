/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from "react-router-dom";
import {
  aboutus,
  boy,
  homeprofile,
  lock,
  login,
  logosmall,
  message,
  paper,
  smallprofile,
} from "../../../assets";
import Header from "../../../components/Navbar/header";
import { AppContext } from "../../../context/context";
import ProfileSidebar from "../../../components/Navbar/profileSidebar";
import { Box, Button, TextField } from "@mui/material";

function CMS() {
  const { state } = AppContext();
  const { constants } = state[0];
  const { main_route, cms, home, admin_faq, admin_terms_and_conditions } =
    constants;
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("profile"));
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(event);
    navigate(home);
  };
  return (
    <section className="cms-section">
      <div className="container">
        {/* <div className="home-header d-flex justify-content-between">
          <img src={logosmall} alt="logo" className="logo" />
          <img src={homeprofile} alt="homeprofile" className="homeprofile" />
        </div> */}
        <Header />
        <div className="d-flex">
          <ProfileSidebar />

          <div className="termscondition">
            <h2>Edit Profile</h2>
            
            <Box width="65vw" mt={3} component="form" onSubmit={handleSubmit}>
              <TextField
                label="Name"
                variant="outlined"
                defaultValue={profile?.name}
                fullWidth
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                borderRadius: '13px', // Adjust the radius as needed
              },
                }}
              />

              <Box
                sx={{ display: "flex", justifyContent: "end", gap: 3, mt: 2,  }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  sx={{ color: "#6f42c1", border: "1px solid #6f42c1", borderRadius: "13px" }}
                  onClick={() => navigate(home)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ background: "#6f42c1", borderRadius: "13px" }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CMS;
