import React from "react";
import {
  Box,
  Button,
  CardHeader,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/context";
import { Card } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../components/Buttons/Buttons";

const eventData = [
  {
    id: 1,
    user_name: "Tom",
    email: "tom@gmail.com",
    // status: true,
    actual_id: "CAT001",
  },
  {
    id: 2,
    user_name: "Bunny",
    email: "bunny@gmail.com",
    // status: true,
    actual_id: "CAT002",
  },
  {
    id: 3,
    user_name: "Bob",
    email: "bob@gmail.com",
    // status: true,
    actual_id: "CAT003",
  },
  {
    id: 4,
    user_name: "Noody",
    email: "noody@gmail.com",
    // status: true,
    actual_id: "CAT004",
  },
  {
    id: 5,
    user_name: "Reem",
    email: "reem@gmail.com",
    // status: true,
    actual_id: "CAT005",
  },
];

const User = () => {
  const navigate = useNavigate();
  const { state } = AppContext();
  const { constants } = state[0];
  const { admin_user_add } = constants;
  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 70,
      headerAlign: "left",
      align: "left",
      renderHeader: (params) => (
        <div style={{ paddingLeft: "1rem" }}> {params.colDef.headerName} </div>
      ),
      renderCell: (params) => (
        <div style={{ paddingLeft: "1rem" }}> {params.value} </div>
      ),
    },
    {
      field: "user_name",
      headerName: "USER NAME",
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "EMAIL",
      minWidth: 250,
      flex: 1.5,
      sortable: false,
    },
    // { field: "status", headerName: "ACTIVE STATUS", minWidth: 150, flex: 1,sortable: false,
    //   renderCell: (params) => {
    //     return <Switch  defaultChecked
    //     // onChange={() => {
    //     //   console.log("Toggled status for", params.row.id);
    //     // }}
    //     inputProps={{ 'aria-label': 'controlled' }}
    //     />
    //   }
    //  },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          // width="100%"
          marginTop="12px"
        >
          <IconButton
            sx={{
              border: "1px solid #e3e3e3",
              borderRadius: "0.25rem",
              padding: "0.4rem",
            }}
          >
            <EditOutlinedIcon
              sx={{ width: "1rem", height: "1rem" }}
              onClick={() => navigate(`/event/${params.row.actual_id}`)}
            />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid #e3e3e3",
              borderRadius: "0.25rem",
              padding: "0.4rem",
            }}
          >
            <VisibilityOutlinedIcon
              sx={{ width: "1rem", height: "1rem" }}
              onClick={() => navigate(`/view/${params.row.actual_id}`)}
            />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid #e3e3e3",
              borderRadius: "0.25rem",
              padding: "0.4rem",
            }}
            // onClick={(event) => handleOpenPopover(event, params.row.actual_id)}
          >
            <DeleteOutlinedIcon sx={{ width: "1rem", height: "1rem" }} />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        User List
      </Typography>
      <Box sx={{ gap: 2 }}>
        <Buttons path={admin_user_add} name="User" />
        <Card
          sx={{
            boxShadow: "none",
            // border: "1px solid #e3e3e3",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <CardHeader
            title=""
            style={{ display: "flex", paddingBottom: "1rem", fontWeight: 700 }}
            action={
              <Box
                display="flex"
                flexDirection={{ xs: "column-reverse", sm: "row" }}
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  // type="search"
                  sx={{
                    minWidth: 150,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                    },
                    "& .MuiOutlinedInput-input::placeholder": {
                      color: "#757575",
                      opacity: 1,
                    },
                  }}
                  placeholder="Search for users..."
                  // value={searchTerm}
                  // onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            }
          />

          <DataGrid
            // loading={state?.isLoading}
            rows={eventData?.map((itm, idx) => ({
              id: idx + 1,
              user_name: itm.user_name,
              email: itm.email,
              // status: itm.status,
              actual_id: itm.id,
            }))}
            columns={columns}
            autoHeight
            // initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[10, 15]}
            disableColumnResize
            disableRowSelectionOnClick
            disableColumnMenu
            sx={{
              pt: 0,
              mt: 2,
              boxShadow: "none",
              "& .MuiDataGrid-container--top [role=row]": {
                backgroundColor: "#e3e3e3 ",
                color: "#666",
              },
            }}
          />
        </Card>
      </Box>
    </Box>
  );
};

export default User;
