import { Box } from "@mui/material";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/global.scss";
import Navbar from "./components/Navbar/Navbar";
import Signin from "./screens/Comman/Signin/Signin";
import AdminRoutes from "./routes/AdminRoutes";
import UserRoutes from "./routes/UserRoutes";

function AppLayout() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* {isAdminRoute  && <Navbar />} */}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          display: "flex",
          flexDirection: "column",
          // width: {
          //   sm: isAdminRoute ? "calc(100% - 240px)" : "100%",
          // },
          height: "100vh",
          // marginTop: "-1.5rem"
          // overflow: "auto",
          // marginTop: isAdminRoute ? "5rem" : "-1.5rem",
        }}
      >
        <Routes>
        <Route path="/" element={<Signin />} />        
        </Routes>
        {isAdminRoute ? <AdminRoutes /> : <UserRoutes />}        
      </Box>
    </Box>
  );
}

function App() {
  return (
    <Router>
      <AppLayout />
    </Router>
  );
}

export default App;
