import { Routes, Route } from "react-router-dom";

import Dashboard from "../screens/Admin/Dashboard/Dashboard";
import Categories from "../screens/Admin/Category/Categories";
import AddCategories from "../screens/Admin/Category/AddCategories";
import SubCategories from "../screens/Admin/SubCategory/SubCategories";
import AddSubCategories from "../screens/Admin/SubCategory/AddSubCategories";
import User from "../screens/Admin/User/User";
import AddUser from "../screens/Admin/User/AddUser";
import Faq from "../screens/Admin/FAQ/Faq";
import AddFaq from "../screens/Admin/FAQ/AddFaq";
import Feedbacks from "../screens/Admin/Feedback/Feedbacks";
import TermsAndConditions from "../screens/TermsAndConditions/TermsAndConditions";
import AboutUs from "../screens/AboutUs/AboutUs";

function AdminRoutes() {
  return (
    <Routes>
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/category" element={<Categories />} />
      <Route path="/admin/category/add" element={<AddCategories />} />
      <Route path="/admin/sub-category" element={<SubCategories />} />
      <Route path="/admin/sub-category/add" element={<AddSubCategories />} />
      <Route path="/admin/user" element={<User />} />
      <Route path="/admin/user/add" element={<AddUser />} />
      <Route path="/admin/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/admin/about-us" element={<AboutUs />} />
      <Route path="/admin/faq" element={<Faq />} />
      <Route path="/admin/faq/add" element={<AddFaq />} />
      <Route path="/admin/feedbacks" element={<Feedbacks />} />
    </Routes>
  );
}

export default AdminRoutes;
