import React from 'react';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';

const AddSubCategories = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        // console.log({ categoryName, description, status });
      };
  return (
    <Box>
     <Typography variant='h6' >
        Add Sub-Category
     </Typography>

     <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: '2rem',
      }}
    >
      <TextField
        label="Category Name"
        variant="outlined"
        fullWidth
        required
      />

      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        required
      />

      <FormControlLabel
        control={<Switch />}
        label="Active Status"
      />

      <Box sx={{display: "flex",justifyContent: "end", gap: 3}} >
        <Button type='button' variant='outlined' >
          Cancel
        </Button>
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
      </Box>

      
    </Box>
    </Box>
  )
}

export default AddSubCategories
