import React from 'react';
import { Box, Button, IconButton, Switch, Typography } from '@mui/material'
import {DataGrid} from "@mui/x-data-grid"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from 'react-router-dom';

const eventData = [
    {
      id: 1,
      category_name: "Electronics",
      category_description: "Devices and gadgets like phones, laptops, and TVs.",
      status: true,
      actual_id: "CAT001",
    },
    {
      id: 2,
      category_name: "Home Appliances",
      category_description: "Household items like refrigerators, washing machines, and air conditioners.",
      status: true,
      actual_id: "CAT002",
    },
    {
      id: 3,
      category_name: "Fashion",
      category_description: "Clothing, footwear, and accessories.",
      status: true,
      actual_id: "CAT003",
    },
    {
      id: 4,
      category_name: "Books",
      category_description: "Various genres of books including fiction, non-fiction, and educational.",
      status: true,
      actual_id: "CAT004",
    },
    {
      id: 5,
      category_name: "Sports & Fitness",
      category_description: "Sporting goods, fitness equipment, and accessories.",
      status: true,
      actual_id: "CAT005",
    },
  ]

const SubCategories = () => {
    const navigate  = useNavigate();
    const columns = [
      {
        field: "id",
        headerName: "ID",
        minWidth: 70,
        headerAlign: "left",
        align: "left",
        renderHeader: (params) => (
          <div style={{ paddingLeft: "1rem" }}> {params.colDef.headerName} </div>
        ),
        renderCell: (params) => (
          <div style={{ paddingLeft: "1rem" }}> {params.value} </div>
        ),
      },
      { field: "category_name", headerName: "CATEGORY NAME", minWidth: 150, flex: 1, sortable: false, },
      {
        field: "category_description",
        headerName: "CATEGORY DESCRIPTION",
        minWidth: 250,
        flex: 1.5,
        sortable: false,
      },
      { field: "status", headerName: "ACTIVE STATUS", minWidth: 150, flex: 1,sortable: false,
        renderCell: (params) => {
          return <Switch  defaultChecked 
          // onChange={() => {
          //   console.log("Toggled status for", params.row.id);
          // }}
          inputProps={{ 'aria-label': 'controlled' }}
          />
        }
       },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        width: 150,
        renderCell: (params) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            // width="100%"
            marginTop="12px"
          >
            <IconButton
              sx={{
                border: "1px solid #e3e3e3",
                borderRadius: "0.25rem",
                padding: "0.4rem",
              }}
            >
              <EditOutlinedIcon
                sx={{ width: "1rem", height: "1rem" }}
                onClick={() => navigate(`/event/${params.row.actual_id}`)}
              />
            </IconButton>
            <IconButton
              sx={{
                border: "1px solid #e3e3e3",
                borderRadius: "0.25rem",
                padding: "0.4rem",
              }}
            >
              <VisibilityOutlinedIcon
                sx={{ width: "1rem", height: "1rem" }}
                onClick={() => navigate(`/view/${params.row.actual_id}`)}
              />
            </IconButton>
            <IconButton
              sx={{
                border: "1px solid #e3e3e3",
                borderRadius: "0.25rem",
                padding: "0.4rem",
              }}
              // onClick={(event) => handleOpenPopover(event, params.row.actual_id)}
            >
              <DeleteOutlinedIcon sx={{ width: "1rem", height: "1rem" }} />
            </IconButton>
          </Box>
        ),
      },
    ];
    return (
      <Box>
        <Typography variant='h5' sx={{mb: 2}} >
        Sub-Categories List
        </Typography>
        <Box sx={{gap:2}}>
          <Button variant='outlined' sx={{mb:2}} onClick={() => navigate('/admin/sub-category/add')} >
            + Add Sub-Category
          </Button>
        <DataGrid
              // loading={state?.isLoading}
              rows={eventData?.map((itm, idx) => ({
                id: idx + 1,
                category_name: itm.category_name,
                category_description: itm.category_description,
                status: itm.status,
                actual_id: itm.id,
              }))}
              columns={columns}
              autoHeight
              // initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[10, 15]}
              disableColumnResize
              disableRowSelectionOnClick
              disableColumnMenu
              sx={{
                pt: 2,
                boxShadow: "none",              
                "& .MuiDataGrid-container--top [role=row]": {
                  backgroundColor: "#e3e3e3 ",               
                  color: "#666",
                },
              }}
            />
        </Box>
      </Box>
    )
}

export default SubCategories
