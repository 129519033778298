import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { Box, InputBase } from '@mui/material';
import { avtar1, logo } from '../../assets';
import { Search as SearchIcon, } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/context';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

const drawerWidth = 240;

const CustomInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      color: '#484848',
      '::placeholder': {
        color: '#484848',
        opacity: 1,
      },
    },
  }));

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = AppContext();
    const {constants } = state[0];
    const {admin_category, admin_dashboard, admin_faq, admin_feedbacks, admin_sub_category, admin_user, admin_about_us,admin_terms_and_conditions, main_route } = constants;

    const [mobileOpen, setMobileOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const [selectedPath, setSelectedPath] = useState(location.pathname);
    const profile = JSON.parse(localStorage.getItem('profile'));

    useEffect(() => {
      setSelectedPath(location.pathname);
    }, [location]);

    const navbarList = [
      {
          name: 'Dashboard',
          path: admin_dashboard,
          icon: <SpaceDashboardOutlinedIcon />
      },
      {
          name: 'Category', path: admin_category,
          icon: <ClassOutlinedIcon />
          // subcategories: [
          //   {name :'Category', path: admin_category},
          //   {name : 'Sub category ', path: admin_sub_category},
          // ],
        },
        {
          name: "User", path: admin_user,
          icon: <GroupOutlinedIcon />
        },
        {
          name: "Terms & Conditions", path: admin_terms_and_conditions,
          icon: <GavelOutlinedIcon />
        },
        {
          name: 'FAQ', path: admin_faq,
          icon: <LiveHelpOutlinedIcon  />
        },
        {
          name: 'Share Feedbacks', path: admin_feedbacks,
          icon: <RateReviewOutlinedIcon />
        },
        {
          name: "About Us", path: admin_about_us,
          icon: <InfoOutlinedIcon  />
        },
        {
          name: "Logout", path: main_route,
          icon: <ExitToAppOutlinedIcon />
        }
    ];
    

    const handleNavigation = (path, hasSubcategories) => {
        if(hasSubcategories){
          setOpenCategory(!openCategory);
        }else if(path){
          navigate(path);
        }
    }

      const drawer = (
        <div>
          <Box sx={{cursor: "pointer"}} onClick={() => navigate(admin_dashboard)} >
          <img src={logo} alt="logo" height={60} width={162} style={{marginLeft:'2rem'}}/>
          </Box>
          <Divider />
          <List sx={{pt: 2,}}>
            {navbarList.map((item, idx) => {
              const selectedMenu = item.path !== main_route && selectedPath?.startsWith(item.path)
              return <div key={idx}>
              <ListItem button 
                        onClick={() => handleNavigation(item.path, !!item.subcategories)}
                        sx={{cursor: "pointer", 
                             background: selectedMenu ? "#6B599C" : "inherit",
                             color: selectedMenu ? "white" : "inherit",
                             '&:hover' : {backgroundColor: selectedMenu ? "#6B599C": "#e1dcec",}
                             }} >
                <ListItemIcon sx={{color: selectedMenu ? "white" : "",}}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
                {item.subcategories ? (openCategory ? <ExpandLess /> : <ExpandMore />) : null}
              </ListItem>
              {item.subcategories && openCategory && (
                <List component="div" disablePadding>
                  {item.subcategories.map((sub, subIdx) => (
                    <ListItem button key={subIdx} sx={{ pl: 4, cursor: "pointer" }} onClick={() => navigate(sub.path)} >
                      <ListItemText primary={sub.name} />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
})}
        </List>
      </div>
      );
      

  return (
    <Box sx={{ display: 'flex',  }}>
      <AppBar
        position="fixed"
        sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px`,
              backgroundColor: "transparent",
              padding : '4px 0px',
              boxShadow: "none",
              marginBottom: '1rem',
               } }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', pt:0.5}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            sx={{ mr: 2, display: { sm: 'none' } ,color: '#484848' }}
          >
            <MenuIcon />
          </IconButton>

          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: '8px',
            width: '40%',
            border: "1px solid lightgray"
          }}
        >
          <IconButton sx={{ p: '10px', color: '#484848' }}>
            <SearchIcon />
          </IconButton>
          <CustomInput
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search',}}
          />
        </Box>

          {/* <Avatar alt="Profile" src="/static/images/avatar/1.jpg" sx={{ backgroundColor: "#365aa1"}} /> */}
          <img src={ profile?.src } alt={profile?.alt} height={45} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}

export default Navbar
