import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

const cardData = [
    {
        title: 'Total categories',
        logo: <CategoryOutlinedIcon />,
        total: 10,
    },
{
        title: 'Total sub categories',
        logo: <CategoryOutlinedIcon />,
        total: 5,
    },
{
        title: 'Total videos',
        logo: <CategoryOutlinedIcon />,
        total: 100,
    },
{
        title: 'Total User',
        logo: <CategoryOutlinedIcon />,
        total: 65,
    },
]

const Cards = () => {
  return (
    <Box sx={{display: "flex", gap: 3, justifyContent: "center"}} >
    {
        cardData?.map((itm,idx) => (
            <Card sx={{
                display: "flex",
                border: "1px solid lightgray", 
                borderRadius: "18px",
                padding: "15px 15px",
                boxShadow: "none", 
                alignItems: "center", 
                flex: 1 }} 
                key={idx}>

                <Box sx={{ backgroundColor: "#7b5eea", display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: '12px',
                    height: 50,
                    width: 50,
                    marginRight: 2,
                    color: "white"
                }}>
                {itm.logo}
                </Box>

            <CardContent>
            <Typography
                variant="body1"
                color="#686666"
                sx={{ padding: 0 ,}}
              >
                {itm.title}
              </Typography>
              <Typography variant="h5" fontWeight={600} sx={{ padding: 0 }}>
                {itm.total}
              </Typography>
              
            </CardContent>
        </Card>
        ))
    }
   
  </Box>
  )
}

export default Cards
