import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { banner, otpIcon } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/context';


function Otp() {
  const navigate = useNavigate();
  const {state} = AppContext();
  const {constants} = state[0];
  const {home, main_route} = constants;
  return (
    <section className="d-flex otpSection auth-section justify-content-between align-items-center">
      <img src={banner} alt="banner" className="auth-art" />
      <div className="otp-detail text-center auth-form">
        <img src={otpIcon} alt="otpImg" className="otpImg" />
        <h2>OTP Verification</h2>
        <p>Enter OTP sent to +1 (123) 456-7890</p>
        <div className="d-flex gap-1 justify-content-center">
          <Form.Control type="text" defaultValue='0' />
          <Form.Control type="text" defaultValue='1' />
          <Form.Control type="text" defaultValue='2' />
          <Form.Control type="text" defaultValue='3' />
          <Form.Control type="text" defaultValue='4' />
          <Form.Control type="text" defaultValue='5' />
        </div>
        <button className='btn btn-link btn-text'><h5 className="mb-3">Resend OTP</h5></button>
        <Button className="theme-btn" onClick={() => navigate(main_route)} >Verify</Button>
      </div>
    </section>
  );
}

export default Otp;
