import React from 'react';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/context';

const AddFaq = () => {
  const navigate  = useNavigate();
    const {state} = AppContext();
    const {constants} = state[0];
    const { admin_faq } = constants;

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(admin_faq)
        // console.log({ categoryName, description, status });
      };
  return (
    <Box>
     <Typography variant='h6' >
        Add FAQ
     </Typography>

     <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: '2rem',
      }}
    >
      <TextField
        label="Question"
        variant="outlined"
        fullWidth
        required
      />

      <TextField
        label="Answer"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        required
      />

      {/* <FormControlLabel
        control={<Switch />}
        label="Active Status"
      /> */}

      <Box sx={{display: "flex",justifyContent: "end", gap: 3}} >
        <Button type='button' variant='outlined' onClick={() => navigate(admin_faq)} >
          Cancel
        </Button>
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
      </Box>

      
    </Box>
    </Box>
  )
}

export default AddFaq