import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import Header from "../../components/Navbar/header";
import ProfileSidebar from "../../components/Navbar/profileSidebar";

const AboutUs = () => {
  return (
    //   <Box>
    //   <Typography variant='h5' sx={{mb: 3}}>
    //     About Us
    //   </Typography>
    //   <TextField
    //     label="About Us"
    //     variant="outlined"
    //     fullWidth
    //     multiline
    //     rows={10}
    //     required
    //     sx={{mb: 3}}
    //   />

    // <Box sx={{display: "flex",justifyContent: "end", gap: 3}} >
    //     <Button type='button' variant='outlined' >
    //       Cancel
    //     </Button>
    //   <Button type="submit" variant="contained" color="primary">
    //     Submit
    //   </Button>
    //   </Box>
    // </Box>

    <section className="cms-section">
      <div className="container">
        <Header />
        <div className="d-flex">
          <ProfileSidebar />

          <div className="termscondition">
            <h2>About Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nulla eros, viverra ut molestie in, pharetra ultricies augue. Nam at dui luctus, vehicula dui vitae, faucibus metus. Nulla aliquet neque a purus hendrerit, eget rutrum purus dictum. Proin neque mauris, aliquet id feugiat vel, egestas eu odio. Mauris quis mi sit amet odio consectetur volutpat. Nullam mattis ligula sed consectetur egestas. Curabitur tristique aliquam dolor, vestibulum fermentum nisl ornare et. Vestibulum efficitur diam in arcu lacinia lacinia.</p>

          <p>Ut tristique tellus congue ultricies sodales. Duis sodales ipsum a efficitur faucibus. Sed orci orci, lobortis eu suscipit nec, sagittis a magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus ac diam a lorem blandit interdum. Praesent ut justo viverra purus auctor consectetur. Aenean viverra vel justo quis efficitur. Sed ultrices tortor eu elit condimentum tempus.</p>

          <p>Sed iaculis nisi id auctor rutrum. Integer a eros vitae ex fermentum semper eu non mauris. Duis varius vestibulum nibh, eget suscipit est auctor et. Sed malesuada, ligula vel pharetra iaculis, eros mauris sollicitudin elit, sed aliquet nulla quam non massa. Phasellus nec lobortis arcu, vitae luctus velit. Vestibulum mi metus, viverra vel posuere in, sollicitudin nec purus. Aenean malesuada eros at ipsum efficitur, vitae interdum purus fringilla. Donec vel sodales ex. Vivamus interdum fringilla mauris sed sodales. Quisque quis dolor velit. Sed facilisis lobortis ex sed euismod. Curabitur ut eros fermentum ex vestibulum dictum.</p>

          <p>Quisque fermentum suscipit lorem ac lacinia. Fusce pellentesque cursus tellus, in facilisis odio fringilla in. Nulla odio nulla, ultrices ut interdum nec, malesuada et tortor. Aenean magna arcu, molestie nec lobortis ac, tristique nec eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed id sem at ante eleifend scelerisque. Praesent ut fermentum metus. Vivamus a bibendum nisl, at tristique turpis. Maecenas dapibus dapibus est in auctor. Mauris ut turpis gravida, fermentum arcu ac, scelerisque nunc. Nunc pellentesque risus eget lacus imperdiet, at sollicitudin nisl consequat.</p>

          <p>Donec sit amet neque velit. Duis sed ex tristique, suscipit massa at, dapibus nisl. Morbi vitae turpis at neque aliquam mattis. Vestibulum vel euismod ex, in venenatis neque. Curabitur molestie nunc quis sem euismod, eget rutrum libero posuere. Suspendisse venenatis tincidunt lectus sit amet sollicitudin. Maecenas suscipit elementum sagittis. Curabitur consequat metus facilisis rutrum vehicula. Aliquam vel tincidunt mi, id accumsan arcu.</p>
        
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
