import React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Header from "../../../components/Navbar/header";
import ProfileSidebar from "../../../components/Navbar/profileSidebar";

const eventData = [
  {
    id: 1,
    category_name: "Electronics",
    category_description: "Devices and gadgets like phones, laptops, and TVs.",
    status: true,
    actual_id: "CAT001",
  },
  {
    id: 2,
    category_name: "Home Appliances",
    category_description:
      "Household items like refrigerators, washing machines, and air conditioners.",
    status: true,
    actual_id: "CAT002",
  },
  {
    id: 3,
    category_name: "Fashion",
    category_description: "Clothing, footwear, and accessories.",
    status: true,
    actual_id: "CAT003",
  },
  {
    id: 4,
    category_name: "Books",
    category_description:
      "Various genres of books including fiction, non-fiction, and educational.",
    status: true,
    actual_id: "CAT004",
  },
  {
    id: 5,
    category_name: "Sports & Fitness",
    category_description: "Sporting goods, fitness equipment, and accessories.",
    status: true,
    actual_id: "CAT005",
  },
];

const Faq = () => {
  const navigate = useNavigate();
  const { state } = AppContext();
  const { constants } = state[0];
  const { admin_faq_add } = constants;
  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 70,
      headerAlign: "left",
      align: "left",
      renderHeader: (params) => (
        <div style={{ paddingLeft: "1rem" }}> {params.colDef.headerName} </div>
      ),
      renderCell: (params) => (
        <div style={{ paddingLeft: "1rem" }}> {params.value} </div>
      ),
    },
    {
      field: "category_name",
      headerName: "CATEGORY NAME",
      minWidth: 150,
      flex: 1,
      sortable: false,
    },
    {
      field: "category_description",
      headerName: "CATEGORY DESCRIPTION",
      minWidth: 250,
      flex: 1.5,
      sortable: false,
    },
    {
      field: "status",
      headerName: "ACTIVE STATUS",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <Switch
            defaultChecked
            // onChange={() => {
            //   console.log("Toggled status for", params.row.id);
            // }}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 150,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          // width="100%"
          marginTop="12px"
        >
          <IconButton
            sx={{
              border: "1px solid #e3e3e3",
              borderRadius: "0.25rem",
              padding: "0.4rem",
            }}
          >
            <EditOutlinedIcon
              sx={{ width: "1rem", height: "1rem" }}
              onClick={() => navigate(`/event/${params.row.actual_id}`)}
            />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid #e3e3e3",
              borderRadius: "0.25rem",
              padding: "0.4rem",
            }}
          >
            <VisibilityOutlinedIcon
              sx={{ width: "1rem", height: "1rem" }}
              onClick={() => navigate(`/view/${params.row.actual_id}`)}
            />
          </IconButton>
          <IconButton
            sx={{
              border: "1px solid #e3e3e3",
              borderRadius: "0.25rem",
              padding: "0.4rem",
            }}
            // onClick={(event) => handleOpenPopover(event, params.row.actual_id)}
          >
            <DeleteOutlinedIcon sx={{ width: "1rem", height: "1rem" }} />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    // <Box sx={{}}>
    //   <Typography variant="h5" sx={{ mb: 2 }}>
    //     FAQ List
    //   </Typography>
    //   {/* <Box sx={{gap:2}}>
    //       <Button variant='outlined' sx={{mb:2}} onClick={() => navigate(admin_faq_add)} >
    //         + Add FAQ
    //       </Button>
    //     <DataGrid
    //           // loading={state?.isLoading}
    //           rows={eventData?.map((itm, idx) => ({
    //             id: idx + 1,
    //             category_name: itm.category_name,
    //             category_description: itm.category_description,
    //             status: itm.status,
    //             actual_id: itm.id,
    //           }))}
    //           columns={columns}
    //           autoHeight
    //           // initialState={{ pagination: { paginationModel } }}
    //           pageSizeOptions={[10, 15]}
    //           disableColumnResize
    //           disableRowSelectionOnClick
    //           disableColumnMenu
    //           sx={{
    //             pt: 2,
    //             boxShadow: "none",
    //             "& .MuiDataGrid-container--top [role=row]": {
    //               backgroundColor: "#e3e3e3 ",
    //               color: "#666",
    //             },
    //           }}
    //         />
    //     </Box> */}

    //   <Box sx={{}}>
    //   <Accordion  sx={{boxShadow: "none", border: "1px solid lightgray"}}>
    //     <AccordionSummary
    //       expandIcon={<ExpandMoreIcon />}
    //       aria-controls="panel1-content"
    //       id="panel1-header"
    //     >
    //       Accordion 1
    //     </AccordionSummary>
    //     <AccordionDetails>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //       malesuada lacus ex, sit amet blandit leo lobortis eget.
    //     </AccordionDetails>
    //   </Accordion>
    //   <Accordion sx={{boxShadow: "none", border: "1px solid lightgray"}}>
    //     <AccordionSummary
    //       expandIcon={<ExpandMoreIcon />}
    //       aria-controls="panel2-content"
    //       id="panel2-header"
    //     >
    //       Accordion 2
    //     </AccordionSummary>
    //     <AccordionDetails>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //       malesuada lacus ex, sit amet blandit leo lobortis eget.
    //     </AccordionDetails>
    //   </Accordion>
    //   <Accordion defaultExpanded sx={{boxShadow: "none", border: "1px solid lightgray"}} >
    //     <AccordionSummary
    //       expandIcon={<ExpandMoreIcon />}
    //       aria-controls="panel3-content"
    //       id="panel3-header"
    //     >
    //       Accordion Actions
    //     </AccordionSummary>
    //     <AccordionDetails>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //       malesuada lacus ex, sit amet blandit leo lobortis eget.
    //     </AccordionDetails>
    //     <AccordionActions>
    //       <Button>Cancel</Button>
    //       <Button>Agree</Button>
    //     </AccordionActions>
    //   </Accordion>
    //   </Box>

    // </Box>

    <section className="cms-section">
      <div className="container">
        <Header />
        <div className="d-flex">
          <ProfileSidebar />

          {/* <div className="termscondition">
            <h2>Conditions générales</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nulla eros, viverra ut molestie in, pharetra ultricies augue. Nam at dui luctus, vehicula dui vitae, faucibus metus. Nulla aliquet neque a purus hendrerit, eget rutrum purus dictum. Proin neque mauris, aliquet id feugiat vel, egestas eu odio. Mauris quis mi sit amet odio consectetur volutpat. Nullam mattis ligula sed consectetur egestas. Curabitur tristique aliquam dolor, vestibulum fermentum nisl ornare et. Vestibulum efficitur diam in arcu lacinia lacinia.</p>

            <p>Ut tristique tellus congue ultricies sodales. Duis sodales ipsum a efficitur faucibus. Sed orci orci, lobortis eu suscipit nec, sagittis a magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus ac diam a lorem blandit interdum. Praesent ut justo viverra purus auctor consectetur. Aenean viverra vel justo quis efficitur. Sed ultrices tortor eu elit condimentum tempus.</p>

            <p>Sed iaculis nisi id auctor rutrum. Integer a eros vitae ex fermentum semper eu non mauris. Duis varius vestibulum nibh, eget suscipit est auctor et. Sed malesuada, ligula vel pharetra iaculis, eros mauris sollicitudin elit, sed aliquet nulla quam non massa. Phasellus nec lobortis arcu, vitae luctus velit. Vestibulum mi metus, viverra vel posuere in, sollicitudin nec purus. Aenean malesuada eros at ipsum efficitur, vitae interdum purus fringilla. Donec vel sodales ex. Vivamus interdum fringilla mauris sed sodales. Quisque quis dolor velit. Sed facilisis lobortis ex sed euismod. Curabitur ut eros fermentum ex vestibulum dictum.</p>

            <p>Quisque fermentum suscipit lorem ac lacinia. Fusce pellentesque cursus tellus, in facilisis odio fringilla in. Nulla odio nulla, ultrices ut interdum nec, malesuada et tortor. Aenean magna arcu, molestie nec lobortis ac, tristique nec eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed id sem at ante eleifend scelerisque. Praesent ut fermentum metus. Vivamus a bibendum nisl, at tristique turpis. Maecenas dapibus dapibus est in auctor. Mauris ut turpis gravida, fermentum arcu ac, scelerisque nunc. Nunc pellentesque risus eget lacus imperdiet, at sollicitudin nisl consequat.</p>

            <p>Donec sit amet neque velit. Duis sed ex tristique, suscipit massa at, dapibus nisl. Morbi vitae turpis at neque aliquam mattis. Vestibulum vel euismod ex, in venenatis neque. Curabitur molestie nunc quis sem euismod, eget rutrum libero posuere. Suspendisse venenatis tincidunt lectus sit amet sollicitudin. Maecenas suscipit elementum sagittis. Curabitur consequat metus facilisis rutrum vehicula. Aliquam vel tincidunt mi, id accumsan arcu.</p>
          </div> */}

          <div className="termscondition">
          <h2>FAQ</h2>
         
          <Box sx={{width: "65vw"}}>
            <Accordion
              sx={{ boxShadow: "none", border: "1px solid lightgray" }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Accordion 1
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ boxShadow: "none", border: "1px solid lightgray" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Accordion 2
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
            <Accordion
              
              sx={{ boxShadow: "none", border: "1px solid lightgray" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Accordion Actions
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
              <AccordionActions>
                <Button>Cancel</Button>
                <Button>Agree</Button>
              </AccordionActions>
            </Accordion>
          </Box>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
