import React from 'react';
import { Box, Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import { AppContext } from '../../../context/context';
import { useNavigate } from 'react-router-dom';
import { back } from '../../../assets';
import { BackButton } from '../../../components/Buttons/Buttons';

const AddCategories = () => {
  const navigate = useNavigate();
  const {state} = AppContext();
  const {constants } = state[0];
  const {admin_category} = constants;
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log({ categoryName, description, status });
        navigate(admin_category)
      };
  return (
    <Box>
      <BackButton path={admin_category} />
     <Typography variant='h6' mt={3} >
        Add Category
     </Typography>

     <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: '2rem',
      }}
    >
      <TextField
        label="Category Name"
        variant="outlined"
        fullWidth
        required
      />

      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        required
      />

      <FormControlLabel
        control={<Switch defaultChecked />}
        label="Active Status"
        
      />

      <Box sx={{display: "flex",justifyContent: "end", gap: 3}} >
        <Button type='button' variant='outlined' onClick={() => navigate(admin_category)}>
          Cancel
        </Button>
      <Button type="submit" variant="contained" color="primary">
        Create
      </Button>
      </Box>      
    </Box>
    </Box>
  )
}

export default AddCategories
