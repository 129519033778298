import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { banner, hideIcon, Logo, logo, showIcon } from "../../../assets";
import { useState } from "react";
import { AppContext } from "../../../context/context";

function Signin() {
  const navigate = useNavigate();
  const {state} = AppContext();
  const {constants} = state[0];
  const {profile, otp} = constants;
  const [showPwd, setShowPwd] = useState(false);
  return (
    <section className="d-flex singnInSection auth-section align-items-center">
      <img src={banner} alt="banner" className="auth-art" />
      <div className="login-detail text-center auth-form">
        <img src={Logo} alt="logo" className="logo" />
        <h2>Log in into your account</h2>
        <div>
          <Form.Group className="mb-3" controlId="email"  >
            <Form.Control type="email" placeholder="Email" defaultValue="admin@gmail.com" />
          </Form.Group>
          <Form.Group className="mb-3 position-relative" controlId="password">
            <Form.Control type={showPwd ? "text" : "password"} placeholder="password" defaultValue="123123" />
            <Button className="show" onClick={() => setShowPwd(!showPwd)} >
              <img src={showPwd ? showIcon : hideIcon} alt="logo" />
            </Button>
          </Form.Group>
        </div>
        <Link to={otp}>
          <h5 className="mb-3">Forgot Password?</h5>
        </Link>

        <Button className="theme-btn" onClick={() => navigate(profile)} > Log In </Button>

        {/* <div className="d-flex justify-content-center gap-2">
          <h4>Don’t have an account?</h4>
          <Link to="/" className="mb-0">
            <h4>Create Account</h4>
          </Link>
        </div> */}
      </div>
    </section>
  );
}

export default Signin;
