import React from 'react'
import Header from '../../../components/Navbar/header'
import ProfileSidebar from '../../../components/Navbar/profileSidebar'
import { Box, Button, Grid, TextField } from '@mui/material'
import { AppContext } from '../../../context/context'
import { useNavigate } from 'react-router-dom'

const ChangePwd = () => {
    const { state } = AppContext();
    const { constants } = state[0];
    const { home, change_password } =
      constants;
    const navigate = useNavigate();
    const profile = JSON.parse(localStorage.getItem("profile"));
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(event);
      navigate(home)
    };
  return (
    <section className="cms-section">
    <div className="container">
      <Header />
      <div className="d-flex">
        <ProfileSidebar />

        <div className="termscondition">
          <h2>Change Password</h2>

          <Box
        component="form"
        width="65vw"
        onSubmit={handleSubmit}
        mt={3}
      >
        <Grid container spacing={3}  >
          <Grid item xs={12} md={4}>
            <TextField
              label="Current Password"
              variant="outlined"
              fullWidth
              required
              sx={{
                '& .MuiOutlinedInput-root': {
              borderRadius: '13px', // Adjust the radius as needed
            },
              }}
            />
          </Grid>

        <Grid item xs={12} md={4}>
        <TextField label="New Password" variant="outlined" fullWidth required 
         sx={{
          '& .MuiOutlinedInput-root': {
        borderRadius: '13px', // Adjust the radius as needed
      },
        }}
        />
          </Grid>

        <Grid item xs={12} md={4}>
        <TextField label="Confirm Password" variant="outlined" fullWidth required 
         sx={{
          '& .MuiOutlinedInput-root': {
        borderRadius: '13px', // Adjust the radius as needed
      },
        }}
         />
          </Grid>         
        </Grid>        

        <Box sx={{ display: "flex", justifyContent: "end", gap: 3 ,mt:2 }}
          >
            <Button
              type="button"
              variant="outlined"
              color="primary"
              sx={{color: "#6f42c1",
                  border: "1px solid #6f42c1",
                   borderRadius: "13px"
              }}
              onClick={() => navigate(home)}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{background:"#6f42c1", borderRadius: "13px" }}>
              Save
            </Button>
          </Box>
      </Box>
          {/* <Box width="65vw" mt={3}>

            <TextField
              label="Name"
              variant="outlined"
            //   defaultValue={profile?.name}
              fullWidth
              required
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", gap: 3 , mt: 2 }}
          component="form"
        //   onSubmit={handleSubmit}
          >
            <Button
              type="button"
              variant="outlined"
              color="primary"
              sx={{color: "#6f42c1",
                  border: "1px solid #6f42c1"
              }}
            //   onClick={() => navigate(home)}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{background:"#6f42c1", }}>
              Save
            </Button>
          </Box> */}
        </div>
      </div>
    </div>
  </section>
  )
}

export default ChangePwd